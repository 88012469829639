import { setShowOverlay } from "../store/actions";
import store from "../store/store";
import axios from 'axios';
import configData from "../../src/config.json";
import * as wss from "./wss";
import * as recordingUtils from "./recordingUtils";
import Peer from "simple-peer";
import { fetchTURNCredentials, getTurnIceServers } from "./turn";
import * as Constants from "../Constants";

const defaultConstraints = {
  audio: true,
  video: {
    width: "480",
    height: "360",
  },
};

const onlyAudioConstraints = {
  audio: true,
  video: false,
};

let localStream;

export const ReturnLocalStream = () => {
  return localStream;
};

export const toggleCamera = (isDisabled) => {
  localStream.getVideoTracks()[0].enabled = isDisabled ? true : false;
};

export const handleRecordButtonPressed = (boolVal) => {
  // boolVal == true ? recordingUtils.stopRecording() : recordingUtils.startRecording();
}

export const getLocalPreviewAndInitRoomConnection = async (
  isRoomHost,
  identity,
  roomId = Constants.webRtcRoomId,
  onlyAudio,
  isMeetingStarted,
  firmName,
  leadFullName
) => {
  await fetchTURNCredentials();
  const constraints = onlyAudio ? onlyAudioConstraints : defaultConstraints;

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      console.log("successfuly received local stream");
      localStream = stream;
      if (isRoomHost == true) {
        showLocalVideoPreview(localStream);
      }

      store.dispatch(setShowOverlay(false));
      isRoomHost
        ? wss.createNewRoom(identity, onlyAudio, leadFullName, firmName, Constants.webRtcRoomId)
        : wss.joinRoom(identity, roomId, onlyAudio, leadFullName, firmName);
    })
    .catch((err) => {
      console.log(
        "error occurred when trying to get an access to local stream"
      );
      console.log(err);
    });
};

let peers = {};
let streams = [];

const getConfiguration = () => {
  const turnIceServers = getTurnIceServers();

  if (turnIceServers) {
    return {
      iceServers: [
        {
          urls: "stun:stun.1und1.de:3478",
        },
        ...turnIceServers,
      ],
    };
  } else {
    console.warn("Using only STUN server");
    return {
      iceServers: [
        {
          urls: "stun:stun.1und1.de:3478",
        },
      ],
    };
  }
};

const messengerChannel = "messenger";

export const prepareNewPeerConnection = async (connUserSocketId, isInitiator) => {

  setTimeout(() => {
    SendSignalForStartRecording();
  }, 2000);

  const configuration = getConfiguration();

  peers[connUserSocketId] = new Peer({
    initiator: isInitiator,
    config: configuration,
    stream: localStream,
    channelName: messengerChannel,
  });

  peers[connUserSocketId].on("signal", (data) => {
    // webRTC offer, webRTC Answer (SDP informations), ice candidates

    const signalData = {
      signal: data,
      connUserSocketId: connUserSocketId,
    };

    wss.signalPeerData(signalData);
  });

  peers[connUserSocketId].on("stream", (stream) => {
    console.log("new stream came");
    addStream(stream, connUserSocketId);
    streams = [...streams, stream];
  });


};

const SendSignalForStartRecording = async () => {
  const identity = await ReturnIdentity();

  axios.post(configData.WEBFORMSERVERAPI_URL + "/webrtclogs/startwebrtcvideo", {
    FirmId: identity.firmId,
    LeadId: identity.leadId,
    AgentId: identity.userId
  }, {
    headers: { "Authorization": `Bearer ${identity.token}`, 'Content-Type': 'multipart/form-data' }
  }).then(function (response) {
    if (response.data.success == true) {

    } else {

    }
  })
    .catch(function (response) {

      if (response.data) {

      } else {

      }
    });
}



const ReturnIdentity = async () => {

  let Token = "";
  let FirmId = "";
  let UserId = "";
  let LeadId = "";
  let OperationClaimId = "";
  //   var Token = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1IiwidW5pcXVlX25hbWUiOiJCdXJjdSBCYWxjxLEiLCJyb2xlIjoiQWdlbnQiLCJuYmYiOjE2OTE4NzkwMDMsImV4cCI6MTY5MjMxMTAwMywiaWF0IjoxNjkxODc5MDAzfQ.O7Emc5vv5lkLtUYKeQpdWVTE1IvJhCJXx5DMlbtN7Xa6iGT_2IFRTzm4CToXJxp6HkHUrafxUe_XeRyo_AkWCw";
  // var FirmId = 1;
  // var UserId = 5;
  var url = window.location.search.substring(1); //get rid of "?" in querystring
  var qArray = url.split('&'); //get key-value pairs
  for (var i = 0; i < qArray.length; i++) {
    var pArr = qArray[i].split('='); //split key and value
    if (pArr[0] == "token")
      Token = pArr[1]; //return value
    if (pArr[0] == "firmid")
      FirmId = pArr[1]; //return value
    if (pArr[0] == "userid")
      UserId = pArr[1]; //return value
    if (pArr[0] == "leadid")
      LeadId = pArr[1]; //return value
    if (pArr[0] == "operationclaimid")
      OperationClaimId = pArr[1]; //return value
  }
  var Identity = {
    token: Token,
    firmId: FirmId,
    userId: UserId,
    operationClaimId: OperationClaimId,
    leadId: LeadId
  };
  return Identity;
}

export const handleSignalingData = (data) => {
  //add signaling data to peer connection
  peers[data.connUserSocketId].signal(data.signal);
};

export const removePeerConnection = (data) => {
  const { socketId } = data;
  const videoContainer = document.getElementById(socketId);
  const videoEl = document.getElementById(`${socketId}-video`);

  if (videoContainer && videoEl) {
    const tracks = videoEl.srcObject.getTracks();

    tracks.forEach((t) => t.stop());

    videoEl.srcObject = null;
    videoContainer.removeChild(videoEl);

    videoContainer.parentNode.removeChild(videoContainer);

    if (peers[socketId]) {
      peers[socketId].destroy();
    }
    delete peers[socketId];
  }
};

////////////////////////////////// UI Videos //////////////////////////////////
const showLocalVideoPreview = (stream) => {
  const videosContainer = document.getElementById("videos_portal");
  videosContainer.classList.add("videos_portal_styles");
  const videoContainer = document.createElement("div");
  videoContainer.classList.add("video_track_container");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.muted = true;  //burası commentli kalmalı.. açılırsa kayıt videosunda wease tarafından ses gelmiyor..
  videoElement.srcObject = stream;


  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoContainer.appendChild(videoElement);

  if (store.getState().connectOnlyWithAudio) {
    videoContainer.appendChild(getAudioOnlyLabel());
  }


  if (Constants.token != undefined) { ///bu agent....
    let FirmName = decodeURI(store.getState().firmName);
    videoContainer.appendChild(VideoTextFunc(FirmName));
  } else {
    ///burası lead tarafında lead yerine geçen text (elle yazıldığı için boş set ediliyor)
    videoContainer.appendChild(VideoTextFunc(""));
  }


  videosContainer.appendChild(videoContainer);
};

const addStream = (stream, connUserSocketId) => {
  //display incoming stream
  const videosContainer = document.getElementById("videos_portal");
  const videoContainer = document.createElement("div");
  videoContainer.id = connUserSocketId;
  videoContainer.classList.add("video_track_container");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.srcObject = stream;
  videoElement.id = `${connUserSocketId}-video`;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoElement.addEventListener("click", () => {
    if (videoElement.classList.contains("full_screen")) {
      videoElement.classList.remove("full_screen");
    } else {
      videoElement.classList.add("full_screen");
    }
  });

  videoContainer.appendChild(videoElement);
  const participants = store.getState().participants;
  const participant = participants.find((p) => p.socketId === connUserSocketId);
  console.log(participant);
  if (Constants.token == undefined) {
    ///videoContainer.appendChild(VideoTextFunc(Constants.firmName));/// burası lead tarafında agent yerine geçen text
    // videoContainer.appendChild(VideoTextFunc(store.getState().leadFullName)); 
    if (store.getState().isRoomHost == true) {
      videoContainer.appendChild(VideoTextFunc(store.getState().firmName));
    } else {
      //burada firma ismi basılmalı
      let firmName = store.getState().firmName;
      videoContainer.appendChild(VideoTextFunc(firmName));   ///burası agent tarafında lead yerine geçen text
    }

  } else {
    videoContainer.appendChild(VideoTextFunc(participant.leadFullName));
  }
  videosContainer.appendChild(videoContainer);
};

const getAudioOnlyLabel = (identity = "") => {
  const labelContainer = document.createElement("div");
  labelContainer.classList.add("label_only_audio_container");
  const label = document.createElement("p");
  label.classList.add("label_only_audio_text");
  label.innerHTML = `Only audio ${identity}`;
  labelContainer.appendChild(label);
  return labelContainer;
};

const VideoTextFunc = (name) => {
  const labelContainer = document.createElement("div");
  labelContainer.classList.add("video_text_container");
  const label = document.createElement("p");
  label.classList.add("video_text_container_text");
  label.innerHTML = name;
  labelContainer.appendChild(label);
  return labelContainer;
};

////////////////////////////////// Buttons logic //////////////////////////////////

export const toggleMic = (isMuted) => {
  localStream.getAudioTracks()[0].enabled = isMuted ? true : false;
};


export const toggleScreenShare = (
  isScreenSharingActive,
  screenSharingStream = null
) => {
  if (isScreenSharingActive) {
    switchVideoTracks(localStream);
  } else {
    switchVideoTracks(screenSharingStream);
  }
};

const switchVideoTracks = (stream) => {
  for (let socket_id in peers) {
    for (let index in peers[socket_id].streams[0].getTracks()) {
      for (let index2 in stream.getTracks()) {
        if (
          peers[socket_id].streams[0].getTracks()[index].kind ===
          stream.getTracks()[index2].kind
        ) {
          peers[socket_id].replaceTrack(
            peers[socket_id].streams[0].getTracks()[index],
            stream.getTracks()[index2],
            peers[socket_id].streams[0]
          );
          break;
        }
      }
    }
  }
};

